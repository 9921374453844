import React from "react"
import Layout from "../components/layout"
import PostMain from "../components/post-main"
import { graphql } from 'gatsby';

const WpPage = ({data}) => {
  const {wpPage={}} = data || {};
  return (
    <Layout>
      <PostMain {...wpPage} />
    </Layout>
  )}

export default WpPage

export const query = graphql`
    query($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            date(formatString: "MMMM DD, YYYY")
        }
    }
`
